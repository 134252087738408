<template>
  <div
    class="surveyModal"
  >
    <div
      class="survey__modal__back font-Pretendard"
      :style="$screen.breakpoint !== 'mobile' ? {left: 'calc(50% - 280px)', width: '560px'} : {left: 0, width: '100%'}"
    >
      <div
        class="modal modal_block_end tablet:p-8 tablet:my-10 mobile:p-4 mobile:max-h-screen"
        :style="{'border-radius': $screen.breakpoint !== 'mobile' ? '8px' : '0px'}"
      >
        <div 
          tablet:m-8
          :class="$screen.breakpoint !== 'mobile' ? 'text-left': 'text-center'"
        >
          <span class="relative inline-block font-bold text-gray-700 tablet:text-3xl tablet:pb-0 mobile:text-lg mobile:pb-4">
            <span class="relative z-10">쿡앱스 브랜드 인식 조사</span>
          </span>
          <button
            class="float-right font-bold tablet:text-3xl tablet:pt-0 mobile:text-xl mobile:pt-1 el-icon el-icon-close"
            @click="close"
          />
        </div>
        <div
          v-if="$screen.breakpoint === 'mobile'"
          class="w-full"
          style="border-top: 1px solid #e1e1e1;"
        />
        <div class="py-4">
          <span 
            class="text-gray-600 
                  tablet:text-base tablet:leading-[1.63] 
                  mobile:text-sm mobile:leading-[1.57]"
          >
            * 해당 설문은 쿡앱스의 채용 과정을 개선하기 위한 목적으로만 사용됩니다.
          </span>
        </div>
        <div
          style="border-top: 1px solid #efefef;"
        >
          <ul class="text-left tablet:mt-4 mobile:mt-5">
            <li>
              <span 
                class="block text-gray-700 font-bold 
                    tablet:text-xl tablet:leading-[1.3] 
                    mobile:text-lg mobile:leading-[1.33]"
              >
                1. 본인의 직군이 어떻게 되시나요?
                <i class="text-orange-500">*</i>
              </span>
              <p class="mt-2 text-sm leading-[1.29] text-gray-500">
                (필수)
              </p>
              <div class="mt-4">
                <el-radio-group v-model="selectA1">
                  <el-row>
                    <el-col
                      v-for="a1 in q1"
                      :key="a1"
                      :span="$screen.breakpoint !== 'mobile' ? 12: 24"
                      class="mb-3"
                    >
                      <el-radio
                        class="text-gray-700 tablet:text-base tablet:leading-[1.25] mobile:text-sm mobile:leading-[1.29]"
                        :label="a1"
                      >
                        {{ a1 }}
                      </el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
            </li>
            <li
              class="tablet:mt-5 mobile:mt-[37px]"
            >
              <span class="block font-bold text-gray-700 tablet:text-xl tablet:leading-[1.3] mobile:text-lg mobile:leading-[1.33]">
                2. 쿡앱스의 강점이 무엇이라고 생각하시나요?
                <i class="text-orange-500">*</i>
              </span>
              <p class="mt-2 text-sm leading-[1.29] text-gray-500">
                (필수 / 복수 응답 가능)
              </p>
              <div class="mt-4">
                <el-checkbox-group v-model="selectA2">
                  <el-row>
                    <el-col
                      v-for="a2 in q2"
                      :key="a2"
                      :span="$screen.breakpoint !== 'mobile' ? 12: 24"
                      class="tablet:mb-2 mobile:mb-[10px]"
                    >
                      <el-checkbox
                        :label="a2"
                        class="text-gray-700 tablet:text-base tablet:leading-[1.25] tablet:tracking-[-0.1px] mobile:text-sm mobile:leading-[1.29]"
                      >
                        {{ a2 }}
                      </el-checkbox>
                    </el-col>
                  </el-row>
                  <div
                    v-show="selectA2.includes('기타')"
                  >
                    <el-input
                      v-model="etc2"
                      placeholder="기타 강점"
                    />
                  </div>
                </el-checkbox-group>
              </div>
            </li>
            <li
              class="mt-9"
            >
              <span class="block font-bold text-gray-700 tablet:text-xl tablet:leading-[1.3] mobile:text-lg mobile:leading-[1.33]">
                3. 쿡앱스의 약점이 무엇이라고 생각하시나요?
                <i class="text-orange-500">*</i>
              </span>
              <p class="mt-2 text-sm leading-[1.29] text-gray-500">
                (필수 / 복수 응답 가능)
              </p>
              <div class="mt-4">
                <el-checkbox-group v-model="selectA3">
                  <el-row>
                    <el-col
                      v-for="a3 in q3"
                      :key="a3"
                      :span="$screen.breakpoint !== 'mobile' ? 12: 24"
                      class="tablet:mb-2 mobile:mb-[9px]"
                    >
                      <el-checkbox
                        :label="a3"
                        class="text-gray-700 tablet:text-base tablet:leading-[1.25] tablet:tracking-[-0.1px] mobile:text-sm mobile:leading-[1.29]"
                      >
                        {{ a3 }}
                      </el-checkbox>
                    </el-col>
                  </el-row>
                  <div
                    v-show="selectA3.includes('기타')"
                  >
                    <el-input
                      v-model="etc3"
                      placeholder="기타 약점"
                    />
                  </div>
                </el-checkbox-group>
              </div>
            </li>
          </ul>
          <div style="text-align: right; margin-top: 40px;">
            <el-button
              type="primary"
              class=""
              style="background-color: #ff9933; height: 48px; font-size: 18px; font-weight: bold; line-height: 1.33; letter-spacing: -0.1px;;"
              :style="{width: $screen.breakpoint !== 'mobile' ? '146px' : '100%'}"
              @click="submit"
            >
              설문 완료
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>import { putSurveyResult } from '../../api/applicant';


export default {
  data(){
    return{ 
      selectA1: '게임기획/시나리오',
      selectA2: [],
      selectA3: [],
      etc2: '',
      etc3: '',
      q1: ['게임기획/시나리오', '아트', '프로그래밍', '마케팅', '인사/조직문화', '비디오/미디어', '데이터 분석', '기타'],
      q2: ['높은 임금 수준 및 복지', '커리어 성장 가능성', '글로벌 서비스 노하우', '애자일한 개발 문화', '높은 고용 안정성', '능력 중심의 수평적 기업 문화', '변화, 도전을 장려하는 기업 문화', '투명한 정보 공개 및 공유','기타'],
      q3: ['타 회사 대비 낮은 임금 및 복지', '불투명한 커리어 성장', '유명 IP 게임의 부재', '경직된 개발 문화', '낮은 고용 안정성', '직급, 연차 중심의 수직적 기업 문화', '안정을 추구하는 기업 문화', '불투명한 정보 공개 및 공유', '기타'],
    };
  },
  mounted() {
    document.getElementById('app').style.overflowY = 'hidden';
    // document.getElementById('app').style.height = '1250px';
    document.getElementById('app').style.height = '100vh';
  },
  destroyed() {
    document.getElementById('app').style.overflowY = 'auto';
    document.getElementById('app').style.height = '';
  },
  methods: {
    close(){
      sessionStorage.setItem('survey', false);
      this.$emit('close');
    },
    async submit(){
      if(!this.selectA2.length || !this.selectA3.length || (this.selectA2.includes('기타') && this.etc2 === '') || (this.selectA3.includes('기타') && this.etc3 === '')){
        alert ('필수 선택 사항을 확인해주세요');
        return; 
      }
      await putSurveyResult({
        content: {
          releaseDate: '20221114', // 혹시 나중에 수정될까 구분용
          a1: this.selectA1,
          a2: this.selectA2,
          a3: this.selectA3,
          etc2: this.etc2,
          etc3: this.etc3,
        }
      });
      this.close();
      sessionStorage.setItem('survey', false);
      localStorage.setItem('survey', false);
      setTimeout(()=>{
        alert('설문이 완료되었습니다. 참여해주셔서 감사합니다.');
      },500);
    }
  },
};
</script>
<style>
.surveyModal {
  position: fixed;
  z-index: 1001;
  top: 0; left: 0; bottom:0;
  width: 100vw; height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
}
.survey__modal__back{
  width: 560px;
  margin: 0 auto;
  z-index: 99999;
}
.modal {
  background-color: white;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.modal_block_end {
  padding-block-end: 2rem;
}
@media (max-width : 767px) {
  .modal {
    max-height: 100vh;
  }
  .modal_block_end {
    padding-block-end: 90px;
  }
}
.el-radio__inner {
  width: 20px;
  height: 20px;
}
.el-radio__inner::after {
  width: 6px;
  height: 6px;
}
.el-checkbox__inner {
  width: 20px;
  height: 20px;
}
span.el-radio__label {
  font-family: Pretendard !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: -0.1px !important;
  color: #444 !important;
}
@media (max-width : 767px) {
  span.el-radio__label {
    padding-left: 8px;
  }
}

.el-checkbox__label {
  font-family: Pretendard !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: -0.1px !important;
  color: #444 !important;
}
.el-checkbox__inner::after {
  left: 6px;
  width: 6px;
  height: 12px;
}

</style>