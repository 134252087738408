import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';
import Layout from '../layout';
import store from '../store';

import confirm from '@/components/modal/confirmModal';
import recruitDelayModal from '@/components/modal/recruitDelayModal';
// import hompageSatisfiedModal from '@/components/modal/hompageSatisfiedModal';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/main/index.vue'),
        meta: { title: 'Home' },
      },
    ],
  },
  {
    path: '/index',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home Index',
        component: () => import('@/views/main/index.vue'),
        meta: { title: 'Home' },
      },
    ],
  },
  {
    path: '/about',
    component: Layout,
    children: [
      {
        path: '',
        name: 'About',
        component: () => import('@/views/about/index.vue'),
        meta: { title: 'About', overflowReset: true },
      },
    ],
  },
  {
    path: '/games',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Games',
        component: () => import('@/views/games/index.vue'),
        hidden: true,
      },
    ],
  },
  {
    path: '/culture',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Culture',
        component: () => import('@/views/culture/index.vue'),
        hidden: true,
      },
    ],
  },
  {
    path: '/story',
    redirect: '/cookapps-story'
    // component: Layout,
    // children: [
    //   {
    //     path: '',
    //     name: 'Story',
    //     component: () => import('@/views/story/index.vue'),
    //     meta: { title: 'Story' },
    //   },
    //   {
    //     path: ':id',
    //     name: 'Story Detail',
    //     component: () => import('@/views/story/detail.vue'),
    //   },
    // ],
  },
  {
    path: '/story/:id',
    redirect:'/cookapps-story/:id'
  },
  {
    path: '/cookapps-story/:id',
    beforeEnter(to) {
      const redirectUrl = 'https://story.cookapps.com/articles/' + to.params.id;
      window.location.href = redirectUrl;
    }
  },
  {
    path: '/cookapps-story',
    beforeEnter() {
      window.location.href = 'https://story.cookapps.com/articles';
    }
  },
  // {
  //   path: '/cookapps-story',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       name: 'Cookapps Story',
  //       component: () => import('@/views/story/index.vue'),
  //       meta: { title: 'Story' },
  //     },
  //     {
  //       path: ':id',
  //       name: 'Cookapps Story Detail',
  //       component: () => import('@/views/story/detail.vue'),
  //       hidden: true,
  //     },
  //   ],
  // },
  {
    path: '/jobs',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Careers',
        // component: () => import('@/views/careers/index.vue'),
        // meta: { title: 'Careers' },
        component: () => import('@/views/careers/pageVersionB.vue'),
        hidden: true,
        props: true,
      },
    ],
  },
  {
    path: '/process',
    component: Layout,
    children: [
      {
        path: '',
        name: 'How We Hire',
        component: () => import('@/views/hiring/index.vue'),
        meta: { title: 'How We Hire' },
      },
    ],
  },
  {
    path: '/faq',
    component: Layout,
    children: [
      {
        path: '',
        name: 'FAQ',
        component: () => import('@/views/faq/index.vue'),
        meta: { title: 'FAQ' },
      },
    ],
  },
  {
    path: '/careers',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Careers Version A',
        component: () => import('@/views/careers/index.vue'),
        meta: { title: 'Careers Version A', isMobileFlag: true },
      },
      // {
      //   path: 'versionA',
      //   name: 'Careers Version A',
      //   component: () => import('@/views/careers/pageVersionA.vue'),
      //   meta: { title: 'Careers Version A' }
      // },
      // {
      //   path: 'versionB',
      //   name: 'Careers Version B',
      //   component: () => import('@/views/careers/pageVersionB.vue'),
      //   meta: { title: 'Careers Version B' }
      // },
      // {
      //   path: ':id',
      //   name: 'Careers Detail',
      //   component: () => import('@/views/careers/detail.vue'),
      //   hidden: true
      // },
      {
        path: 'list',
        name: 'Careers List',
        component: () => import('@/views/careers/pageVersionB.vue'),
        hidden: true,
        props: true,
      },
      {
        path: ':id',
        name: 'Careers Detail',
        component: () => import('@/views/careers/pageVersionB.vue'),
        hidden: true,
        meta: { title: 'Careers' },
      },
      {
        path: 'jobDetail/:id',
        name: 'Job Detail',
        component: () => import('@/views/careers/jobDetail.vue'),
        hidden: true,
        meta: { title: 'Careers' },
      },
    ],
  },
  {
    path: '/recruit',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Recruit',
        component: () => import('@/views/recruit/simpleForm'),
        // component: () => import('@/views/careers/pageVersionB.vue'),
        // hidden: true,
        // props: true,
      },
      {
        path: 'apply',
        name: 'Recruit Apply',
        component: () => import('@/views/recruit/simpleForm'),
        // component: () => import('@/views/careers/pageVersionB.vue'),
        // hidden: true,
        // props: true,
      },
      {
        path: 'sign-up',
        name: 'Recruit Sign Up',
        component: () => import('@/views/recruit/signUp'),
        meta: { title: 'Sign Up', notLoggedIn: true },
      },
      {
        path: 'my-apply',
        name: 'Recruit My Apply',
        component: () => import('@/views/recruit/myApply'),
        meta: { title: 'My Apply' },
      },
      {
        path: 'reset-pwd',
        name: 'Reset Password',
        component: () => import('@/views/recruit/resetPwd'),
        meta: { title: 'Reset Password' },
      },
      {
        path: 'change-pwd',
        name: 'Change Password',
        component: () => import('@/views/recruit/changePwd'),
        meta: { title: 'Change Password', requiresAuth: true },
      },
      // {
      //   path: 'apply',
      //   name: 'Recruit Apply',
      //   component: () => import('@/views/recruit/applyNew'),
      //   meta: { title: 'Recruit Apply', requiresAuth: true },
      // },
      {
        path: 'apply/complete',
        name: 'Recruit Apply Complete',
        component: () => import('@/views/recruit/applyComplete'),
        meta: { title: 'Recruit Apply Complete' },
        props: true,
      },
      {
        path: 'apply-simple-en',
        name: 'Recruit Apply Simple Eng',
        component: () => import('@/views/recruit/applySimpleEN'),
        meta: { title: 'Recruit Apply Simple' },
      },
      {
        path: 'apply-simple-kr',
        name: 'Recruit Apply Simple',
        component: () => import('@/views/recruit/applySimpleKR'),
        meta: { title: 'Recruit Apply Simple' },
      },
      {
        path: 'review-apply',
        name: 'Review Apply',
        component: () => import('@/views/recruit/reviewApply'),
        meta: { title: 'Review Apply', requiresAuth: true },
      },
      {
        path: 'edit-apply',
        name: 'Edit Apply',
        component: () => import('@/views/recruit/applyNew'),
        meta: { title: 'Recruit Apply', requiresAuth: true },
      },
      {
        path: 'job-inform',
        name: 'Job Inform',
        component: () => import('@/views/recruit/jobInform.vue'),
        meta: { title: 'Recruit Assignment' },
      },
      {
        path: ':uuid',
        name: 'Recruit Assignment',
        component: () => import('@/views/recruit/assignment'),
        meta: { title: 'Recruit Assignment' },
      },
      {
        path: 'submission-document/:uuid',
        name: 'Recruit Submission Document',
        component: () => import('@/views/recruit/submissionDocument'),
        meta: { title: 'Recruit submissionDocument' },
      },
    ],
  },
  {
    path: '/tos-n-pp',
    title: 'Terms Of Service',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Terms Of Service',
        component: () => import('@/views/terms-of-service'),
        meta: { title: 'Terms Of Service' },
      },
    ],
  },
  {
    path: '/system',
    title: 'UI System',
    component: Layout,
    children: [
      {
        path: '',
        name: 'UI System',
        component: () => import('@/views/system/sample.vue'),
        meta: { title: 'UI System' },
      },
    ],
  },
];

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to) => {
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: 'smooth',
        };
      }
      return { x: 0, y: 0 };
    },
  });

const router = createRouter();

router.afterEach((to) => {
  if (new Date('2024-04-25').getTime() <= Date.now() && new Date('2024-05-01').getTime() > Date.now()) {
    if (to.fullPath.includes('recruit/apply')) {
      setTimeout(() => {
        router.app.$modal.open(recruitDelayModal);
      }, 2000);
    }
    
    if ((Cookies.get('hideRecruitDelayModalToday') !== 'true') && (to.fullPath.includes('/careers') || to.fullPath === '/careers/list')) {
      setTimeout(() => {
        router.app.$modal.open(recruitDelayModal);
      }, 2000);
    }
  }

  if (to.fullPath.includes('recruit') || to.name === 'Home') {
    return;
  }

  const survey = sessionStorage.getItem('survey') === 'true';
  const accessTime = new Date(sessionStorage.getItem('accessTime'));
  const currentTime = new Date();

  const time = Math.ceil((currentTime - accessTime) / 1000);

  if (localStorage.getItem('survey') === 'true') {
    const lang = navigator.appName === 'Netscape' ? navigator.language.substring(0, 2) : navigator.userLanguage.substring(0, 2);
    if (time >= 300 && survey && lang === 'ko') {
      setTimeout(() => {
        router.app.$modal.open(confirm);
      }, 2000);
    }
  }
});

router.beforeEach((to, from, next) => {
  document.documentElement.style.overflowY = 'auto';

  // 간편지원하기 jobid와 jobtypeid 없을 시 채용공고 리스트 페이지로 이동
  if (to.fullPath === '/recruit/apply' || to.fullPath === '/recruit') {
    next('/careers/list');
  }

  if (to.matched.some((record) => record.meta.isMobileFlag)) {
    if (window.innerWidth < 768) {
      next('/careers/list');
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next('/recruit/sign-up');
    }
  }

  if (to.matched.some((record) => record.meta.notLoggedIn)) {
    if (store.getters.isLoggedIn) {
      store.dispatch('app/setIsSimpleApply', false);
      next('/recruit/my-apply');
    }
  }

  if (to.matched.some((record) => record.meta.overflowReset)) {
    document.documentElement.style.overflowY = 'unset';
    document.documentElement.style.overflowX = 'unset';
  } else {
    document.documentElement.style.overflowY = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  }

  // if (to.matched.some(record => record.meta.scrollSmooth)) {
  //   document.documentElement.style.scrollBehavior = 'smooth';
  // }else{
  //   document.documentElement.style.scrollBehavior = 'unset';
  // }

  next();
});

export default router;
