<template>
  <div class="dimmed_container">
    <div class="flex items-center justify-center h-screen">
      <div
        class="z-[99999] bg-white
              tablet:max-w-[480px] tablet:p-8
              mobile:max-w-[327px] mobile:p-6
              "
        style="
          box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 2px 4px -2px rgba(0, 0, 0, 0.12);
          border-radius: 8px;
        "
      >
        <div class="tablet:mb-2.5 mobile:mb-1.5">
          <span 
            class="font-bold text-gray-800
                  tablet:text-[26px] tablet:leading-[1.23] tablet:tracking-[-0.2px]
                  mobile:text-xl mobile:leading-[1.3]
                  "
          >
            서류 심사 및 결과 안내 지연
          </span>
        </div>
        <div 
          class="text-gray-600
                tablet:mb-6 tablet:text-lg tablet:leading-[1.5]
                mobile:mb-5 mobile:text-sm mobile:leading-[1.43]
                "
        >
          <p>
            워크샵(4.26~4.30)으로 채용 관련 안내가 어려워요. 워크샵 이후 최대한 빠르게 안내해 드릴게요. 지원자 분들의 너그러운 양해 부탁 드려요.
            <!-- 설 연휴 기간 동안 채용 관련 안내가 지연될 수 있어요. 연휴가 끝나면 최대한 빠르게 안내해 드릴게요. -->
            <!-- 4월 24일 ~ 5월 1일까지 전사 워크샵으로 인해 
            <br
              v-if="$screen.breakpoint === 'mobile'"
            >
            서류 심사 및 
            <br
              v-if="$screen.breakpoint !== 'mobile'"
            >
            결과 안내가 어렵습니다. 4월 20일 
            <br
              v-if="$screen.breakpoint === 'mobile'"
            >
            목요일까지 접수된 
            <br
              v-if="$screen.breakpoint !== 'mobile'"
            >
            서류는 해외워크샵 이전에 결과 
            <br
              v-if="$screen.breakpoint === 'mobile'"
            >
            안내가 이루어지며, 이후 
            <br
              v-if="$screen.breakpoint !== 'mobile'"
            >
            접수된 서류는 5월 2일 
            <br
              v-if="$screen.breakpoint === 'mobile'"
            >
            화요일부터 순차적으로 결과 안내가 
            <br
              v-if="$screen.breakpoint !== 'mobile'"
            >
            진행될 
            <br
              v-if="$screen.breakpoint === 'mobile'"
            >
            예정입니다. -->
          </p>
          <!-- <div><span class="text-style-1">20</span>초면 충분합니다!</div> -->
        </div>
        <div class="float-right">
          <button 
            class="w-[76px] h-10 rounded-[4px] bg-orange-400 hover:bg-orange-500 active:bg-orange-500 focus:rounded-[5px] focus:"
            @click.once="close"
          >
            <span class="text-base leading-[1.25] font-bold text-center text-white">확인</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';

export default {
  name: 'RecruitDelayModal',
  data() {
    return {
      showRecruitDelayModal: false,
      hideRecruitDelayModalToday: false,
    };
  },
  mounted() {
    document.getElementById('app').style.overflowY = 'hidden';
    document.getElementById('app').style.height = window.innerHeight+'px';
  },
  destroyed() {
    document.getElementById('app').style.overflowY = 'auto';
    document.getElementById('app').style.height = '';
  },
  methods: {
    close(){
      this.showRecruitDelayModal = false;
      this.hideRecruitDelayModalToday = true;
      Cookies.set('hideRecruitDelayModalToday', 'true', { expires: 1 / 24 });
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.dimmed_container {
  position: fixed;
  z-index: 1001;
  top: 0; left: 0; bottom:0;
  width: 100vw; height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
}
</style>